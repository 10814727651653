import { useState, FC } from 'react';
import { css } from '@emotion/react';
import Button from '../Button';
import { colors, spacing, fontStyles, buildPageSpacing } from '../../styles';
import FeedbackTakeover from '../FeedbackTakeover';
import Hr from '../Hr';
import share from '../../utils/share';

const FeedbackFooter: FC = () => {
  const [isFeedbackTakeoverShown, setIsFeedbackTakeoverShown] = useState(false);

  return (
    <div
      css={css`
        background-color: ${colors.fog};
        margin-left: calc(0px - ${buildPageSpacing(spacing.deci, 'LEFT')});
        margin-bottom: -${spacing.regular};
        width: calc(100% + ${buildPageSpacing(spacing.deci, 'LEFT')} + ${buildPageSpacing(spacing.deci, 'RIGHT')});
      `}
    >
      <FeedbackTakeover isShown={isFeedbackTakeoverShown} onClose={() => setIsFeedbackTakeoverShown(false)} />
      <Hr />
      <div
        css={css`
          padding: 0 ${buildPageSpacing(spacing.deci, 'RIGHT')} ${spacing.hecto}
            ${buildPageSpacing(spacing.deci, 'LEFT')};
        `}
      >
        <h3
          css={css`
            margin: 0;
            ${fontStyles.lion}
          `}
        >
          Are you enjoying Bloom?
        </h3>
        {
          <p
            css={css`
              color: ${colors.silver};
              margin: ${spacing.centi} 0 ${spacing.regular} 0;
              ${fontStyles.flea}
            `}
          >
            Share the app or let us know how we can improve.
          </p>
        }
        <div
          css={css`
            display: flex;
            justify-content: space-around;
            gap: ${spacing.deci};
          `}
        >
          <Button
            variant="secondary"
            onClick={share}
            css={css`
              flex: 1;
            `}
          >
            Share the app
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsFeedbackTakeoverShown(true)}
            css={css`
              flex: 1;
            `}
          >
            Give feedback
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackFooter;
