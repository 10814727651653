import 'normalize.css';
import { extendTheme } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { Rating } from '../apis/backend';

import HelveticaNowDisplayRegular from './fonts/HelveticaNowDisplay-Regular.woff2';
import HelveticaNowTextLight from './fonts/HelveticaNowText-Light.woff2';
import HelveticaNowTextMedium from './fonts/HelveticaNowText-Medium.woff2';
import HelveticaNowTextRegular from './fonts/HelveticaNowText-Regular.woff2';
import HelveticaNowMicroMedium from './fonts/HelveticaNowMicro-Medium.woff2';

export const colors = {
  cloud: '#CBCAC9',
  orange: '#D03F17',
  petrolBlue: '#118383',
  liquorice: '#171616',
  silver: '#74726D',
  sugar: '#fffefa',
  fog: '#F8F8F8',
  tungsten: '#504E4B',
  berry: '#D03F17',
  berryAir: '#FAEBE5',
  earth: '#836714',
  earthAir: '#F2EFE7',
  leaf: '#189D39',
  leafAir: '#E7F5EB',
};

export const boxShadow = 'box-shadow: 0 1px 3px rgba(23, 22, 22, 0.2);';

export const borderRadius = '8px';

export const spacing = {
  milli: '4px',
  centi: '8px',
  deci: '16px',
  regular: '24px',
  deka: '32px',
  hecto: '40px',
  kilo: '48px',
  giga: '64px',
};

export const fontFamilies = {
  helveticaNowText: `'HelveticaNowText', sans-serif`,
  helveticaNowDisplay: `'HelveticaNowDisplay', sans-serif`,
  helveticaNowMicro: `'HelveticaNowMicro', sans-serif`,
};

export const fontStyles = {
  rhino: css`
    font-family: ${fontFamilies.helveticaNowDisplay};
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 1.2rem;
  `,
  shark: css`
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 500;
    font-size: 1.38rem;
    line-height: 1.88rem;
  `,
  roboShark: css`
    font-family: ${fontFamilies.helveticaNowMicro};
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.88rem;
  `,
  horse: css`
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 500;
    font-size: 1.16rem;
    line-height: 1.5rem;
  `,
  roboHorse: css`
    font-family: ${fontFamilies.helveticaNowMicro};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  lion: css`
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 500;
    font-size: 1.06rem;
    line-height: 1.44rem;
  `,
  dog: css`
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  cat: css`
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 400;
    font-size: 0.88rem;
    line-height: 1.31rem;
  `,
  fatCat: css`
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1.31rem;
  `,
  roboCat: css`
    font-family: ${fontFamilies.helveticaNowMicro};
    font-weight: 400;
    font-size: 0.81rem;
    line-height: 1.31rem;
  `,
  mouse: css`
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 500;
    font-size: 0.81rem;
    line-height: 1.19rem;
  `,
  flea: css`
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.06rem;
  `,
  fairyfly: css`
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 500;
    font-size: 0.6875rem;
    line-height: 1.06rem;
  `,
  roboMite: css`
    font-family: ${fontFamilies.helveticaNowMicro};
    font-weight: 400;
    font-size: 0.5625rem;
    line-height: 1.06rem;
  `,
};

export const mediaQuery = {
  small: 'min-width: 576px',
  medium: 'min-width: 768px',
};

export const statusColorMap: { [index: string]: string } = {
  NONE: colors.liquorice,
  POSITIVE: colors.leaf,
  NEGATIVE: colors.berry,
  NEUTRAL: colors.tungsten,
};

export const ratingTextColor: { [index in Rating]: string } = {
  [Rating.Exceptional]: colors.leaf,
  [Rating.Favorable]: colors.leaf,
  [Rating.Average]: colors.silver,
  [Rating.Unfavorable]: colors.orange,
  [Rating.Dangerous]: colors.berry,
  [Rating.Missing]: colors.tungsten,
};

export const calculateChangeColor = (changeRatio = 0): string => {
  if (changeRatio < 0.001 && changeRatio > -0.001) return colors.silver;

  const positiveLowerBoundRgb = [163, 200, 17];
  const positiveUpperBoundRgb = [24, 157, 75];
  const negativeLowerBoundRgb = [212, 162, 31];
  const negativeUpperBoundRgb = [218, 63, 23];

  const lowerBoundRgb = changeRatio > 0 ? positiveLowerBoundRgb : negativeLowerBoundRgb;
  const upperBoundRgb = changeRatio > 0 ? positiveUpperBoundRgb : negativeUpperBoundRgb;

  const maxIntensityChangeRatio = 0.1;

  const calculateGradient = (lowerBoundColorCode: number, upperBoundColorCode: number) => {
    const colorRatio = Math.min(Math.abs(changeRatio) / maxIntensityChangeRatio, 1);
    return Math.round(lowerBoundColorCode + (upperBoundColorCode - lowerBoundColorCode) * colorRatio);
  };

  return `rgb(${calculateGradient(lowerBoundRgb[0], upperBoundRgb[0])},${calculateGradient(
    lowerBoundRgb[1],
    upperBoundRgb[1],
  )},${calculateGradient(lowerBoundRgb[2], upperBoundRgb[2])})`;
};

export const buildPageSpacing = (base: string, side: 'TOP' | 'BOTTOM' | 'LEFT' | 'RIGHT') => {
  const safeAreaMappings: { [index: string]: string } = {
    TOP: 'env(safe-area-inset-top)',
    RIGHT: 'env(safe-area-inset-right)',
    BOTTOM: 'env(safe-area-inset-bottom)',
    LEFT: 'env(safe-area-inset-left)',
  };

  const fullStyle = `calc(${base} + ${safeAreaMappings[side]})`;

  return CSS.supports(`padding: ${fullStyle}`) ? fullStyle : base;
};

export const theme = extendTheme({
  colors: {
    black: colors.tungsten,
    white: colors.sugar,
  },
});

export const getGlobalStyles = () => css`
  @font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('${HelveticaNowDisplayRegular}') format('woff2');
  }

  @font-face {
    font-family: 'HelveticaNowText';
    src: url('${HelveticaNowTextLight}') format('woff2');
    font-weight: 300;
  }

  @font-face {
    font-family: 'HelveticaNowText';
    src: url('${HelveticaNowTextRegular}') format('woff2');
    font-weight: 400;
  }

  @font-face {
    font-family: 'HelveticaNowText';
    src: url('${HelveticaNowTextMedium}') format('woff2');
    font-weight: 500;
  }

  @font-face {
    font-family: 'HelveticaNowMicro';
    src: url('${HelveticaNowMicroMedium}') format('woff2');
    font-weight: 400;
  }

  html {
    box-sizing: border-box;
  }

  @supports (font: -apple-system-body) {
    html {
      font: -apple-system-body;
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: inherit;
  }

  body {
    font-family: ${fontFamilies.helveticaNowText};
    color: ${colors.liquorice};
    font-weight: 400;
    margin: 0;
    ${/* The background color is also set in the HTML file directly to prevent a flash of content on mobile */ ''}
    background-color: ${colors.sugar};
  }

  a {
    color: ${colors.petrolBlue};
    text-decoration: none;
  }

  img {
    display: inline; /* override Chakra */
  }

  blockquote {
    margin: 0;
    padding: 0 ${spacing.deci};
    border-left: ${spacing.milli} solid ${colors.cloud};
  }

  input[type='text'],
  input[type='search'],
  input[type='email'],
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input,
  textarea {
    border: 1px solid ${colors.cloud};
    border-radius: 4px;
    padding: ${spacing.centi} ${spacing.deci};
    background-color: ${colors.sugar};
    outline: none;
    ${fontStyles.mouse}

    &:focus {
      border-color: ${colors.petrolBlue};
    }

    /* Combining this selector doesn't seem to work on Safari */
    &:focus-visible {
      border-color: ${colors.petrolBlue};
    }

    &::placeholder {
      color: ${colors.cloud};
    }
  }
`;
