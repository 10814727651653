import { FC } from 'react';
import { css } from '@emotion/react';
import { Rating } from '../../apis/backend';
import average from './average.svg';
import dangerous from './dangerous.svg';
import exceptional from './exceptional.svg';
import favorable from './favorable.svg';
import missing from './missing.svg';
import unfavorable from './unfavorable.svg';

export enum Size {
  small,
  medium,
  large,
}

interface Props {
  rating: Rating;
  size?: Size;
}

export { Rating };

const imageMapping: { [key: string]: string } = {
  [Rating.Missing]: missing,
  [Rating.Exceptional]: exceptional,
  [Rating.Favorable]: favorable,
  [Rating.Average]: average,
  [Rating.Unfavorable]: unfavorable,
  [Rating.Dangerous]: dangerous,
};

const baseStyles = css`
  vertical-align: middle;
`;

const styles = {
  [Size.small]: css`
    height: 16px;
    width: 16px;
  `,
  [Size.medium]: css`
    height: 24px;
    width: 24px;
  `,
  [Size.large]: css`
    height: 32px;
    width: 32px;
  `,
};

const Indicator: FC<Props> = ({ rating, size = Size.medium }) => {
  const image = imageMapping[rating] ?? missing;
  return <img src={image} css={[baseStyles, styles[size]]} />;
};

export default Indicator;
