import { FC, useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { Spinner } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import posthog from 'posthog-js';
import { Purchases, PurchasesPackage } from '@awesome-cordova-plugins/purchases';
import { css } from '@emotion/react';
import * as stores from '../../stores';
import { colors, fontStyles, spacing } from '../../styles';
import Takeover, { Props as TakeoverProps } from '../Takeover';
import Button from '../Button';
import RadioButton, { Props as RadioButtonProps } from '../RadioButton';
import selected from './selected.svg';
import reportsPaywall from './reports.svg';
import collectionsPaywall from './collections.svg';
import wiimPaywall from './wiim.svg';
import confetti from './confetti.svg';

const PurchaseRadioCard: FC<
  RadioButtonProps & {
    title: string;
    savingsText?: string;
    price: string;
    unit: string;
    priceBreakdown?: string;
    introText?: string;
  }
> = ({ checked, title, savingsText, price, unit, priceBreakdown, introText, ...props }) => {
  return (
    <RadioButton
      {...props}
      checked={checked}
      css={css`
        width: 148px;
        padding: 0;
        text-align: center;
        color: ${colors.tungsten};
        -webkit-tap-highlight-color: transparent;
        display: flex;
        flex-direction: column;
        border: none;
        border-radius: 8px;
        box-shadow: 0 0 0 ${checked ? '2px' : '1px'} ${checked ? colors.petrolBlue : colors.cloud}, 0 2px 3px 0 #e5e5e5;
        overflow: hidden;
      `}
    >
      <div
        css={css`
          background-color: ${checked ? colors.petrolBlue : colors.cloud};
          color: ${checked ? colors.sugar : colors.tungsten};
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: ${spacing.deci};
          ${fontStyles.roboCat}
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          {checked && (
            <img
              src={selected}
              css={css`
                position: absolute;
                left: 0;
                top: -1px;
              `}
            />
          )}
          {title}
        </div>
        <div
          css={css`
            ${fontStyles.flea}
          `}
        >
          {savingsText}
        </div>
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: ${spacing.deci};
        `}
      >
        <div>
          <span
            css={css`
              ${fontStyles.lion}
            `}
          >
            {price}
          </span>{' '}
          <span
            css={css`
              color: ${colors.silver};
              ${fontStyles.flea}
            `}
          >
            /{unit}
          </span>
        </div>
        <div
          css={css`
            color: ${colors.silver};
            ${fontStyles.roboMite}
          `}
        >
          {priceBreakdown}
        </div>
        <div
          css={css`
            padding-top: ${spacing.deci};
            color: ${colors.leaf};
            ${fontStyles.flea}
          `}
        >
          {introText}
        </div>
      </div>
    </RadioButton>
  );
};

const onClose = () =>
  stores.General.update((s) => {
    s.isSubscriptionTakeoverShown = false;
  });

const SubscriptionTakeover: FC<Omit<TakeoverProps, 'isShown' | 'onClose'>> = (props) => {
  const isShown = stores.General.useState((s) => s.isSubscriptionTakeoverShown);
  const [selectedPackage, setSelectedPackage] = useState<PurchasesPackage>();
  const offerings = stores.Purchase.useState((s) => s.offerings);
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const purchaseMutation = useMutation((selectedPackage: PurchasesPackage) =>
    Purchases.purchasePackage(selectedPackage),
  );
  const restoreMutation = useMutation(() => Purchases.restorePurchases());

  useEffect(() => {
    if (offerings?.current?.annual) {
      setSelectedPackage(offerings.current.annual);
    }
  }, [offerings]);

  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  const annualPackage = offerings?.current?.annual;
  const monthlyPackage = offerings?.current?.monthly;

  return (
    <Takeover {...props} title="Bloom membership" isShown={isShown} onClose={onClose} name="membership">
      {isSubscribed ? (
        <div
          css={css`
            text-align: center;
            display: flex;
            flex-direction: column;
            min-height: 100%;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              margin-top: 6vh;
            `}
          >
            <img
              src={confetti}
              css={css`
                height: 150px;
              `}
            />
            <h2
              css={css`
                margin: ${spacing.hecto} 0;
                ${fontStyles.shark}
              `}
            >
              Subscription activated!
            </h2>
            <p
              css={css`
                margin: 0;
                ${fontStyles.dog}
              `}
            >
              Congratuations, you now have access to all Bloom features.
            </p>
          </div>
          <div>
            <Button
              block
              variant="primary"
              onClick={onClose}
              css={css`
                ${fontStyles.horse}
              `}
            >
              Done
            </Button>
          </div>
        </div>
      ) : (
        <div
          css={css`
            min-height: 100%;
          `}
        >
          <div>
            <h2
              css={css`
                margin: 0 0 ${spacing.regular} 0;
                ${fontStyles.horse}
              `}
            >
              Join Bloom today and unlock:
            </h2>
            <ul
              css={css`
                list-style: none;
                padding: 0;
                margin: 0;
                ${fontStyles.mouse}
              `}
            >
              {[
                [wiimPaywall, 'Historical “Why Is It Moving” data. Understand why trends are happening.'],
                [collectionsPaywall, 'All of our curated collections. Discover the most undervalued investments.'],
                [reportsPaywall, 'Full risk factor reports. Unlock knowledge extracted from hundred-page reports.'],
              ].map(([image, text]) => (
                <li
                  key={text}
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: ${spacing.regular};
                  `}
                >
                  <div>
                    <img
                      src={image}
                      css={css`
                        width: 36px;
                      `}
                    />
                  </div>
                  <span
                    css={css`
                      margin-left: ${spacing.deci};
                    `}
                  >
                    {text}
                  </span>
                </li>
              ))}
            </ul>
            <p
              css={css`
                margin: ${spacing.deka} 0;
                ${fontStyles.mouse}
              `}
            >
              And more to come! Thanks to our members — your support means we can continue building new features to make
              investing easier.
            </p>
            <div
              role="radiogroup"
              css={css`
                margin: ${spacing.deka} 0;
                display: flex;
                justify-content: space-evenly;
              `}
            >
              {[monthlyPackage, annualPackage].map((rcPackage) => {
                if (!rcPackage) return null;
                const currencySymbol = rcPackage?.product?.priceString?.[0];

                const packageText: Record<
                  string,
                  { title: string; unit: string; savingsText?: string; priceBreakdown?: string; freeTrialText?: string }
                > = {
                  $rc_monthly: {
                    title: 'Monthly',
                    unit: 'month',
                  },
                  $rc_annual: {
                    title: 'Annual',
                    unit: 'year',
                    savingsText: monthlyPackage
                      ? `Save ${((1 - rcPackage.product.price / 12 / monthlyPackage.product.price) * 100).toFixed(0)}%`
                      : undefined,
                    priceBreakdown: `${currencySymbol}${(rcPackage.product.price / 12).toFixed(2)} /month`,
                  },
                };

                return (
                  <PurchaseRadioCard
                    key={rcPackage.identifier}
                    checked={selectedPackage === rcPackage}
                    price={rcPackage.product.priceString}
                    introText={
                      rcPackage.product.introPrice?.price === 0 && rcPackage.product.introPrice.periodUnit
                        ? `+${
                            rcPackage.product.introPrice.periodNumberOfUnits
                          } ${rcPackage.product.introPrice.periodUnit.toLowerCase()} free trial`
                        : undefined
                    }
                    {...packageText[rcPackage.identifier]}
                    onChange={() => setSelectedPackage(rcPackage)}
                    css={css`
                      margin: 0;
                    `}
                  />
                );
              })}
            </div>
          </div>

          <div>
            {!purchaseMutation.isLoading &&
              purchaseMutation.isError &&
              !(purchaseMutation.error as any)?.userCancelled && (
                <p
                  css={css`
                    margin: ${spacing.centi} 0;
                    text-align: center;
                    color: ${colors.berry};
                    ${fontStyles.flea}
                  `}
                >
                  There was an unexpected error. Please try again.
                </p>
              )}
            <Button
              variant="primary"
              block
              disabled={!selectedPackage || purchaseMutation.isLoading}
              css={css`
                ${fontStyles.horse}
              `}
              onClick={() => {
                (async () => {
                  if (selectedPackage) {
                    try {
                      const { customerInfo } = await purchaseMutation.mutateAsync(selectedPackage);
                      stores.Purchase.update((s) => {
                        s.purchaserInfo = customerInfo;
                      });
                      posthog.capture('Order Completed', {
                        'Package identifier': selectedPackage.identifier,
                      });
                    } catch (e) {
                      if (!e.userCancelled) {
                        Sentry.captureException(e);
                      }
                      console.log(e);
                    }
                  }
                })();
              }}
            >
              <span
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                {purchaseMutation.isLoading ? <Spinner color={colors.sugar} /> : <>Subscribe</>}
              </span>
            </Button>
            <Button
              block
              disabled={restoreMutation.isLoading}
              css={css`
                padding: ${spacing.deci};
                ${fontStyles.mouse}
              `}
              onClick={() => {
                (async () => {
                  const restore = await restoreMutation.mutateAsync();
                  stores.Purchase.update((s) => {
                    s.purchaserInfo = restore;
                  });
                })();
              }}
            >
              {restoreMutation.isLoading ? <Spinner size="sm" color={colors.cloud} /> : <>Restore Purchase</>}
            </Button>
            <div
              css={css`
                color: ${colors.silver};
                text-align: center;
                ${fontStyles.flea}
              `}
            >
              <p
                css={css`
                  margin: ${spacing.centi} 0;
                `}
              >
                <Button
                  onClick={() =>
                    window.open(
                      'https://www.notion.so/Bloom-Terms-and-Conditions-b41a2b80c0b14429801adf810856b1d3',
                      '_blank',
                    )
                  }
                >
                  Terms and Conditions
                </Button>{' '}
                and{' '}
                <Button
                  onClick={() =>
                    window.open('https://www.notion.so/Bloom-Privacy-Policy-0c94935f49344ce4957fe459f4cf128d', '_blank')
                  }
                >
                  Privacy Policy
                </Button>
              </p>
            </div>
          </div>
        </div>
      )}
    </Takeover>
  );
};

export default SubscriptionTakeover;
