import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import * as stores from '../../stores';
import OnboardingPopover from '../OnboardingPopover';
import { colors, spacing, fontStyles } from '../../styles';
import Search from '../Search';
import Page from '../Page';
import CollectionsList from './CollectionsList';

const SearchPage: FC = () => {
  const { appOpenDates, isSearchOnboardingPopoverShown, searchOnboardingPopoverOpenedAt } = stores.General.useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!searchOnboardingPopoverOpenedAt && appOpenDates && appOpenDates.length <= 1) {
      // Set a timeout to hack around positioning issue.
      // Page component, and maybe the safe-area-inset, pushes around the content while loading.
      // Timeout hopefully delays the popover positioning until after the pushing around occurs.
      setTimeout(() => {
        stores.General.update((s) => {
          s.isSearchOnboardingPopoverShown = true;
          s.searchOnboardingPopoverOpenedAt = new Date().toISOString();
        });
      }, 800);
    }
  }, [searchOnboardingPopoverOpenedAt, appOpenDates]);

  return (
    <Page showNavigationBar>
      <h1
        css={css`
          color: ${colors.liquorice};
          margin: 0 0 ${spacing.hecto} 0;
          ${fontStyles.shark}
        `}
      >
        Discover
      </h1>
      <OnboardingPopover
        isOpen={isSearchOnboardingPopoverShown}
        onClose={() =>
          stores.General.update((s) => {
            s.isSearchOnboardingPopoverShown = false;
          })
        }
        renderTrigger={({ styles }) => (
          <div
            css={[
              styles,
              css`
                border-radius: 8px;
              `,
            ]}
          >
            <Search isAllowBookmark />
          </div>
        )}
        renderBody={() => (
          <>
            👋 Hey there! Start by searching for stocks, like <strong>Apple (AAPL)</strong>.
          </>
        )}
      />
      <h2
        css={css`
          margin: ${spacing.kilo} 0 ${spacing.regular};
          ${fontStyles.horse};
        `}
      >
        Find new stocks
      </h2>
      <CollectionsList />
    </Page>
  );
};

export default SearchPage;
