import { css } from '@emotion/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { spacing, fontStyles } from '../../styles';
import Button from '../Button';
import bookmarkIcon from '../BookmarkButton/bookmarkEmpty.svg';

const Empty: FC = () => {
  const styles = {
    wrapper: css`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      ${fontStyles.shark};
      padding: 0 ${spacing.hecto};
      text-align: center;
    `,
    description: css`
      margin-bottom: ${spacing.hecto};
    `,
    bookmarkIcon: css`
      position: relative;
      left: 3px;
      top: 3px;
      height: 24px;
    `,
  };

  return (
    <div css={styles.wrapper}>
      <div css={styles.description}>
        Find stocks and add them to your watchlist with{' '}
        <img css={styles.bookmarkIcon} src={bookmarkIcon} alt="Bookmark" /> .
      </div>
      <div>
        <Link to="/search">
          <Button block variant="secondary">
            Find more stocks
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Empty;
