import { css } from '@emotion/react';
import { HTMLProps, FC } from 'react';
import { colors, spacing, borderRadius } from '../../styles';

type Props = HTMLProps<HTMLDivElement>;

/**
 * Box should not be a clickable component, unlike Card.
 */
const Box: FC<Props> = ({ children, ...props }) => (
  <div
    css={css`
      display: inline-block;
      border-radius: ${borderRadius};
      padding: ${spacing.deci};
      color: ${colors.tungsten};
      border: 1px solid ${colors.cloud};
      background-color: ${colors.sugar};
    `}
    {...props}
  >
    {children}
  </div>
);

export default Box;
