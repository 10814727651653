import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import { Spinner } from '@chakra-ui/react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FC, useState } from 'react';
import ErrorSection from '../../ErrorSection';
import { fontStyles, spacing, colors, ratingTextColor } from '../../../styles';
import { getBottomLine } from '../../../apis/backend';
import Indicator, { Size } from '../../RatingIndicator';
import Button from '../../Button';
import BottomLineExplanationTakeover from '../../BottomLineExplanationTakeover';
import SectionHeading from '../SectionHeading';

interface Props extends Pick<RouteComponentProps, 'match'> {
  symbol: string;
}

const BottomLine: FC<Props> = ({ symbol, match }) => {
  const [isBottomLineExplanationShown, setIsBottomLineExplanationShown] = useState(false);
  const query = useQuery(['investments', symbol, 'bottom_line'], async () => getBottomLine(symbol));

  return (
    <div>
      <BottomLineExplanationTakeover
        isShown={isBottomLineExplanationShown}
        onClose={() => setIsBottomLineExplanationShown(false)}
      />
      <SectionHeading text="Bottom Line" />
      <Button
        onClick={() => setIsBottomLineExplanationShown(true)}
        css={css`
          display: block;
          margin: -${spacing.deci} 0 ${spacing.deka} 0;
          ${fontStyles.mouse}
        `}
      >
        What’s the Bottom Line?
      </Button>
      {(() => {
        if (query.isLoading)
          return (
            <div
              css={css`
                text-align: center;
              `}
            >
              <Spinner color={colors.cloud} size="lg" />
            </div>
          );
        if (query.isError || !query.data)
          return <ErrorSection text="Problem fetching bottom line data." onRetry={() => query.refetch()} />;
        return (
          <>
            <ul
              css={css`
                margin: 0;
                list-style: none;
                position: relative;
                padding-left: 0;
              `}
            >
              {query.data.details.map((detail) => (
                <li key={detail.title}>
                  <div
                    css={css`
                      position: absolute;
                      left: 0;
                      margin-top: -4px;
                    `}
                  >
                    <Indicator rating={detail.rating} size={Size.large} />
                  </div>
                  <h3
                    css={css`
                      margin: ${spacing.deka} 0 ${spacing.deci} 0;
                      padding-left: ${spacing.hecto};
                      ${fontStyles.lion}
                    `}
                  >
                    <span
                      css={css`
                        color: ${ratingTextColor[detail.rating]};
                      `}
                    >
                      {detail.title.split(' ')[0]}
                    </span>{' '}
                    {detail.title.split(' ').slice(1).join(' ')}
                  </h3>
                  <ul
                    css={css`
                      padding-left: 30px;
                      list-style: disc;
                      position: relative;
                      color: ${colors.tungsten};
                      ${fontStyles.mouse}
                    `}
                  >
                    {detail.descriptions.map((element, index) => (
                      <li
                        key={index}
                        css={css`
                          margin-top: ${spacing.centi};
                          padding-left: 12px;

                          &::marker {
                            color: ${ratingTextColor[detail.rating]};
                          }
                        `}
                      >
                        <span
                          css={css`
                            position: relative;
                            top: -1px;
                          `}
                        >
                          {element}
                        </span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
            <Link to={`${match.url}/stats`}>
              <Button
                variant="secondary"
                block
                css={css`
                  margin-top: ${spacing.deka};
                `}
              >
                See more stats and compare
              </Button>
            </Link>
          </>
        );
      })()}
    </div>
  );
};

export default BottomLine;
