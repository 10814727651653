import { css } from '@emotion/react';
import { FC } from 'react';
import { fontStyles, spacing } from '../../styles';

interface Props {
  text: string;
}

const SectionHeading: FC<Props> = ({ text }) => (
  <h3
    css={css`
      ${fontStyles.horse};
      margin: 0 0 ${spacing.regular} 0;
    `}
  >
    {text}
  </h3>
);

export default SectionHeading;
