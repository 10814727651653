import { css } from '@emotion/react';
import { HTMLProps } from 'react';
import { TimeRange } from '../types';
import RadioButton from './RadioButton';

interface Props<T> extends HTMLProps<HTMLDivElement> {
  timeRanges: T[];
  selectedTimeRange?: TimeRange;
  onRadioChange: (timeRange: T) => void;
}

const timeRangesRadios = <T extends TimeRange>({
  timeRanges,
  selectedTimeRange,
  onRadioChange,
  ...props
}: Props<T>) => {
  const isSkeleton = !timeRanges || timeRanges.length === 0;
  return (
    <div
      role="radiogroup"
      css={css`
        display: flex;
        justify-content: space-between;
        visibility: ${isSkeleton ? 'hidden' : 'visible'};
      `}
      {...props}
    >
      {(isSkeleton ? ([TimeRange.days1] as T[]) : timeRanges).map((timeRange) => (
        <RadioButton
          key={timeRange}
          value={timeRange}
          checked={selectedTimeRange === timeRange}
          onChange={() => onRadioChange(timeRange)}
        >
          {timeRange}
        </RadioButton>
      ))}
    </div>
  );
};

export default timeRangesRadios;
