import { FC } from 'react';
import { css } from '@emotion/react';
import Card from '../Card';
import { spacing, fontStyles, colors } from '../../styles';
import lock from './lock.svg';

interface Props {
  name: string;
  imageSrc: string;
  isLocked?: boolean;
}

const CollectionCard: FC<Props> = ({ name, imageSrc, isLocked }) => (
  <Card
    css={css`
      height: 100%;
      width: 100%;
      color: ${colors.petrolBlue};
      text-align: center;
      position: relative;
      padding: ${spacing.centi};
      ${fontStyles.fatCat};
    `}
  >
    {isLocked && (
      <img
        src={lock}
        alt="Locked"
        css={css`
          position: absolute;
          right: 0;
          top: 0;
          padding: inherit;
          width: 52px;
          opacity: 0.7;
        `}
      />
    )}
    <img
      src={imageSrc}
      css={css`
        width: 90%;
      `}
    />
    <div
      css={css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `}
    >
      {name}
    </div>
  </Card>
);

export default CollectionCard;
