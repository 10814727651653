import { css } from '@emotion/react';
import posthog from 'posthog-js';
import { FC, useEffect } from 'react';
import { addBackListener, removeBackListener } from '../../utils/backHandling';
import Button from '../Button';
import { colors, fontStyles, spacing } from '../../styles';
import Page from '../Page';
import closeIcon from '../../assets/close.svg';

export interface Props {
  isShown: boolean;
  title?: string;
  name?: string;
  onClose: () => void;
}

const Takeover: FC<Props> = ({ name, isShown, title, onClose, children }) => {
  useEffect(() => {
    if (isShown) {
      document.body.style.overflow = 'hidden'; // Prevent background scrolling
      posthog.capture('Viewed Takeover', {
        Name: name,
      });
      addBackListener(onClose);
    } else {
      document.body.style.overflow = ''; // Re-enable background scrolling
    }

    return () => {
      document.body.style.overflow = ''; // Ensure scrolling is re-enabled on cleanup
      removeBackListener(onClose);
    };
  }, [isShown, name, onClose]);

  if (!isShown) {
    return null;
  }

  return (
    <Page
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        background-color: ${colors.sugar};
        overflow-y: scroll;
        z-index: 200;
      `}
    >
      <header
        css={css`
          text-align: center;
          margin-bottom: ${spacing.hecto};
          position: relative;
        `}
      >
        <Button
          css={css`
            width: 22px;
            position: absolute;
            left: 0;
          `}
          onClick={onClose}
        >
          <img
            src={closeIcon}
            alt="Close"
            css={css`
              vertical-align: middle;
            `}
          />
        </Button>
        <h1
          css={css`
            ${fontStyles.horse}
            margin: 0 ${spacing.regular} 0 ${spacing.regular};
          `}
        >
          {title}
        </h1>
      </header>

      <div
        css={css`
          flex: 1;
        `}
      >
        {children}
      </div>
    </Page>
  );
};

export default Takeover;
