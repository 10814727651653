import { FC } from 'react';
import { css } from '@emotion/react';
import { colors, fontStyles, spacing } from '../../../styles';
import Button from '../../Button';

interface Props {
  keyPoint: string;
  lineClamp?: string | number;
  onShowDetails: () => void;
}

const RiskFactorPoint: FC<Props> = ({ keyPoint, lineClamp = 'none', onShowDetails }) => (
  <div>
    <blockquote
      css={css`
        color: ${colors.tungsten};
        display: -webkit-box;
        -webkit-line-clamp: ${lineClamp};
        line-clamp: ${lineClamp};
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        ${fontStyles.mouse}
      `}
    >
      {keyPoint}
    </blockquote>
    <Button
      onClick={() => onShowDetails()}
      css={css`
        padding: ${spacing.deci};
        ${fontStyles.mouse}
      `}
    >
      Explore details
    </Button>
  </div>
);

export default RiskFactorPoint;
